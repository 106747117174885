import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import crudRoutes from "./routes/crud";

import CategoriesIndex from "@/domains/categories/components/Index.vue";
import Login from "@/domains/auth/components/Login.vue";
import Home from "@/domains/app/pages/Home.vue";
import News from "@/domains/news/pages/News.vue";
import EditNews from "@/domains/news/pages/Edit.vue";
import AccessDenied from "@/domains/httpErrors/AccessDenied.vue";
import PageNotFound from "@/domains/httpErrors/PageNotFound.vue";
import ResetPassword from "@/domains/auth/components/ResetPassword.vue";
import RequestPasswordReset from "@/domains/auth/components/RequestPasswordReset.vue";
import SheetStatistics from "@/domains/statistics/components/SheetStatistics.vue";
import KmPriceList from "@/domains/cruds/km-prices/pages/List.vue";
import Import from "@/domains/import/pages/Import.vue";
import Exports from "@/domains/exports/pages/Exports.vue";
import { middleware } from "./middleware";
import {
    ABILITY_WRITE_GLOBAL_CRUDS,
    ABILITY_WRITE_NEWS,
} from "@/domains/cruds/users/users";
import FAQ from "@/domains/cruds/faqs/pages/Public.vue";
import Calculator from "@/domains/misc/pages/Calculator.vue";

const routes: Array<RouteRecordRaw> = [];

routes.push({
    name: "index",
    path: "/",
    component: Home,
});

routes.push({
    name: "news",
    path: "/news",
    component: News,
});

routes.push({
    name: "news.edit",
    path: "/news/edit",
    component: EditNews,
    meta: {
        abilities: [ABILITY_WRITE_NEWS],
    },
});

routes.push({
    name: "categories.index",
    path: "/categories",
    component: CategoriesIndex,
});

routes.push({
    name: "statistics",
    path: "/statistics",
    component: SheetStatistics,
});

routes.push({
    name: "import",
    path: "/import",
    component: Import,
});

routes.push({
    name: "download",
    path: "/download",
    component: Exports,
});

routes.push({
    name: "calculator",
    path: "/calculator",
    component: Calculator
})

routes.push({
    name: "auth.login",
    path: "/login",
    component: Login,
    meta: { gest: true },
});

routes.push({
    name: "auth.reset-password",
    path: "/reset-password",
    component: ResetPassword,
    meta: { gest: true },
});

routes.push({
    name: "auth.request-reset-password",
    path: "/reset-password/email",
    component: RequestPasswordReset,
    meta: { gest: true },
});

routes.push({
    name: "km-prices.index",
    path: "/km-prices",
    component: KmPriceList,
    meta: { abilities: [ABILITY_WRITE_GLOBAL_CRUDS] },
});

routes.push({
    name: "faqs.public",
    path: "/foire-aux-questions",
    component: FAQ,
    meta: { gest: true },
});

routes.push({
    name: "error.403",
    path: "/access-denied",
    component: AccessDenied,
    meta: { gest: true },
});

routes.push({
    name: "error.404",
    path: "/page-not-found",
    component: PageNotFound,
    meta: { gest: true },
});

routes.push(...crudRoutes);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeResolve(middleware);

export default router;
